module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-4640162533/0/cache/gatsby-plugin-google-gtag-npm-5.13.1-993d935611-70a88d4abd.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-TYRQSQ9QC3"],"gtagConfig":{"anonymize_ip":true,"allow_google_signals":false,"allow_ad_personalization_signals":false},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-68952e718f/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-45d59a4aa5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mergist - Online PDF Merger","short_name":"Mergist","start_url":"/","background_color":"hsl(220 18% 13%)","theme_color":"#FFCA28","display":"standalone","icons":[{"src":"icons/maskable-icon.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"favicon.svg","sizes":"1024x1024","type":"image/svg+xml","purpose":"any"},{"src":"favicon-32x32.png","sizes":"32x32","type":"image/png","purpose":"any"},{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"}],"include_favicon":false,"theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-8244bc59c9/0/cache/gatsby-plugin-offline-npm-6.13.1-f6177c3827-dada4b2691.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-e3bf678669/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
